import { Link, useParams } from 'react-router-dom';
import GeneralInfo from './GeneralInfo';
import Prescription from './Prescription';
import Questionnaire from './Questionnaire';
import TestResult from './TestResult';
import TumblingEResult from './TumblingEResult';
import NearVisionResult from './NearVisionResult';
import { useEffect, useState } from 'react';
import { getSession } from '../../../api/recheckFunctions';
import Actions from './Actions';
import NewTumblingEResult from './NewTumblingEResult';

const RecheckIndividualSession = () => {
  const [session, setSession] = useState({});
  const [error, setError] = useState(null);
  const { sessionId } = useParams();

  useEffect(() => {
    getSession(sessionId).then((res) => {
      try {
        const data = JSON.parse(res.data);
        setSession({ ...res, data });
      } catch (e) {
        console.error('Error getting result: ', e);
        setError('There was an error getting the results for this session.');
      }
    });
  }, [sessionId]);

  useEffect(() => {
    console.log(session);
  }, [session]);

  const getNestedProperty = (path) => {
    return path.split('.').reduce((o, key) => o?.[key], session);
  };

  const getTestResults = (test) => {
    const tests = getNestedProperty('data.tests');
    if (!tests) return undefined;
    const testResults = tests.find((t) => t.testId === test);
    return testResults;
  };

  const getPrescription = () => {
    const prescription = getNestedProperty('data.prescription');
    const prescription_media = getNestedProperty('prescription_media');
    if (!prescription) return undefined;
    prescription.file = prescription_media;
    return { ...prescription };
  };

  const getQuestionnaire = () => {
    const questionnaire = getNestedProperty('data.questionnaire');
    if (!questionnaire) return undefined;
    return { ...questionnaire };
  };

  return (
    <div className='container-fluid'>
      <div className='card shadow mb-5'>
        <div className='card-header bg-primary-ibis py-3'>
          <h6 className='m-0 font-weight-bold text-white'>{'Recheck - Session Details'}</h6>
        </div>
        <div className='card-body d-flex flex-column align-items-center'>
          {!session.id ? (
            <h3 className='font-weight-bold'>{error || 'Loading...'}</h3>
          ) : (
            <>
              <h5 className='font-weight-bold mb-3'>Recheck Session Results</h5>
              <GeneralInfo session={session} />
              <Prescription prescription={getPrescription()} />
              <Questionnaire questionnaire={getQuestionnaire()} />
              <TestResult test='Amsler Grid' result={getTestResults('amsler')} />
              <NewTumblingEResult test='Tumbling E' result={getTestResults('new-tumbling-e')} />
              <TestResult test='Astigmatism' result={getTestResults('astigmatism')} />
              <NearVisionResult test='Near Vision' result={getTestResults('near-vision')} />
              <div className='card shadow mb-5 w-100'>
                <div className='card-header bg-primary-ibis py-3'>
                  <h6 className='m-0 font-weight-bold text-white text-center'>{'Session Actions'}</h6>
                </div>
                <div className='card-body d-flex flex-column align-items-center'>
                  <Actions session={session} setSession={setSession} />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className='d-flex justify-content-center'>
        <Link to='/recheck/sessions'>
          <button className='btn btn-lg btn-ibis font-weight-bold' style={{ width: '150px' }}>
            Back
          </button>
        </Link>
      </div>
    </div>
  );
};

export default RecheckIndividualSession;
